import React from 'react'
import { graphql } from 'gatsby';
import Layout from "../../components/Layout/LayoutNewYork"
import PageHeader from "../../components/shared/PageHeader"
import styled from "styled-components";

import StyledDownload from "components/shared/StyledInbound";
import DeloreanAnimation from "components/Animations/DeloreanAnimation";
import { media } from 'utils/Media';

const ThankYou = styled.div`
    width: 100%;
    /* height: calc(90vh); */
    background: #fff;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    section {
        width: 90%;
        margin: 0 auto;
        padding-bottom: 3rem;
        .button {
            text-align: center;
            margin: 0 auto;
            
        }
        h1 , p { 
            text-align: center;
        }
        .video {
            margin: 2rem auto;
            text-align: center;
            width: 100%;
            height: 100%;
            iframe {
                width: 100%;
                max-width: 650px;
                margin: 0 auto;
                @media ${media.md} {
                    min-height: 400px;
                }
                
            }
        }
    }
    
`


const NewYorkThankYouPage = ({ data }) => {
    const { logo, videoImage } = data 
    return (
        <Layout title="NYC Sign up | New York | BTTF"
                description="The official place to buy tickets for Back To The Future The Musical. Now playing at the Adelphi Theatre in London."
                booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
                signUpHidden={true}
        >
            <PageHeader city="new-york"/>
            <ThankYou>
                <section>
                    <DeloreanAnimation />
                    <h1>Thank you for signing up</h1>
                    <div className="video">
                        {/* <iframe src="https://youtu.be/8CYSQlduLFg" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="BTTF-SIGNUP2">
                            </iframe> */}
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/8CYSQlduLFg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="button mt-5">
                        <StyledDownload link="/Back-to-the-Future-The-Musical-Broadway.mp4"> 
                            DOWNLOAD THE VIDEO TO SHARE
                        </StyledDownload>
                    </div>
                </section>
            </ThankYou>
        </Layout>
    )
}

export default NewYorkThankYouPage


export const NewYorkThankYouPageQuery = graphql`
  query NewYorkThankYouPageQuery {
    logo: file(relativePath: { eq: "BTTF-LOGO-2020-NEW.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500)
      }
    }
    videoImage: file(relativePath: { eq: "HomePage/new-trailer.png" }) {
                    childImageSharp {
                       gatsbyImageData(width: 1200)
                    }
                }
   
  }
`; 