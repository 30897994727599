import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

const BookTicketsBtnStyled = styled(Link)`
    color: white;
    padding: .25rem;
    text-transform: uppercase;
    position: relative;
    vertical-align: middle;
    transition: all .4s ease;
    display: inline-block;
    line-height: 1;
    font-family: ${props => props.theme.font.family.blackItalic};
    font-weight: 900;
    font-style: italic;
    font-size: ${props => props.theme.font.size.xl};
    text-decoration: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    a {
        text-decoration: none!important;
    }
    * {
        color: white !important;
    }

    div {
        background: linear-gradient(190deg, #FF0000 17.93%, #FF7503 38.01%, #FFD200 60.03%);
        background-size: 100% 200%;
        padding: .3rem;
        position: relative;
        transition: all .4s ease;

        span {
            background-color: black;
            display: block;
            padding: .25rem 1.25rem;
            position: relative;
            z-index: 4;

            ${props => props.grey && css`
                background-color: ${props => props.theme.colors.blackOff};
            `}
        }

        &:before {
            content: "";
            position: absolute;
            background-color: black;
            width: 20%;
            max-width: 2.5rem;
            height: calc(100% + 8px);
            z-index: 3;
            right: 20%;
            top: -4px;
            transform: skewX(-40deg);

            ${props => props.grey && css`
                background-color: ${props => props.theme.colors.blackOff};
            `}
        }

    }

    &:before,
    &:after {
        content: '';
        box-sizing: inherit;
        position: absolute;
        /* Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts */
        border: 1px solid transparent;
        width: 100%;
        height: 100%;
    }

    /* This covers the top & right borders (expands right, then down) */
    &:before {
        top: 0;
        right: 0;
        border-right-color: ${props => props.theme.colors.red};
            border-bottom-color: ${props => props.theme.colors.red};
            width: 100%;
            height: 100%; 
    }

    /* And this the bottom & left borders (expands left, then up) */
    &:after {
        bottom: 0;
        left: 0;
        border-left-color: ${props => props.theme.colors.red};
            border-top-color: ${props => props.theme.colors.red};
            width: 100%;
            height: 100%;
    }

    &:hover {
        color: ${props => props.theme.colors.white};
        text-decoration: none;

        div {
            animation-name: gradient;
            animation-duration: .4s;
            animation-fill-mode: both;
            background: linear-gradient(180deg,#FFD200 47.93%,#FF7503 68.01%,#FF0000 90.03%);
            background-size: 100% 200%;
        }

        &:before,
        &:after {
            width: 100%;
            height: 100%;
        }

        &:before {
            /* Make borders visible */
            border-right-color: ${props => props.theme.colors.red};
            border-bottom-color: ${props => props.theme.colors.red}; 
            transition:
                height 0.2s ease-out, /* height expands first */
                width 0.1s ease-out 0.2s; /* Then width */
        }

        &:after {
            border-left-color: ${props => props.theme.colors.red};
            border-top-color: ${props => props.theme.colors.red}; 
            transition:
                border-color 0s ease-out 0.3s,
                height 0.2s ease-out 0.2s, 
                width 0.2s ease-out 0.2s; 
        }
    }
    
    &:focus {
        div {
            background: ${props => props.theme.colors.white};
        }
    }

    @keyframes gradient {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: 0% 100%;
        }
    }
    
    ${props => props.$inverse && css`
        color: black;
        
        * {
            color: black !important;
        }
    
        &:before {
            border-right-color: ${props => props.theme.colors.red};
            border-bottom-color: ${props => props.theme.colors.red};
            width: 100%;
            height: 100%; 
        }

        &:after {
            border-left-color: ${props => props.theme.colors.red};
            border-top-color: ${props => props.theme.colors.red};
            width: 100%;
            height: 100%;
        } 
        
        &:hover {
            color: ${props => props.theme.colors.black};
        }
        
        &:focus {
            div {
                background: ${props => props.theme.colors.black};
            }
        }
        
        div span {
            background-color: white;
        }
        
        div:before {
            background-color: white;
        }
        
    `}
    
    ${props => props.red && css`
        color: white;
        
        * {
            color: white !important;
        }
        
        &:before {
            border-right-color: ${props => props.theme.colors.red};
            border-bottom-color: ${props => props.theme.colors.red};
            width: 100%;
            height: 100%; 
        }

        &:after {
            border-left-color: ${props => props.theme.colors.red};
            border-top-color: ${props => props.theme.colors.red};
            width: 100%;
            height: 100%;
        } 
        
        &:hover {
            color: ${props => props.theme.colors.black};
        }
        
        &:focus {
            div {
                background: ${props => props.theme.colors.black};
            }
        }
        
        div span {
            background-color: #EF1C0F;
        }
        
        div:before {
            background-color: #EF1C0F;
        }
    `}

    ${props => props.white && css`
        color: black;
        
        * {
            color: black !important;
        }

        &:before {
            border-right-color: white;
            border-bottom-color: white;
            background: white;
            width: 100%;
            height: 100%; 
        }

        &:after {
            border-left-color: white;
            border-top-color: white;
            width: 100%;
            height: 100%;
        } 
        
        &:hover {
            color: ${props => props.theme.colors.black};


            div {
                background: linear-gradient(180deg,#FFD200 47.93%,#FF7503 68.01%,#FF0000 90.03%);
            }
            &:before {
                /* Make borders visible */
                border-right-color: white;
                border-bottom-color: white; 
                background: white;
            }

            &:after {
                border-left-color: white;
                border-top-color: white; 
            }
        }
        
        &:focus {
            div {
                background: ${props => props.theme.colors.black};
            }
        }
        
        div span {
            background-color: white;
        }
        
        div:before {
            background-color: white;
        }
    `}
    

`

const BookTicketsBtn = (props) => {
    const {link} = props 
    return (

    <BookTicketsBtnStyled href={link} download {...props}>
                    <div>
                        <span>{props.children}</span>
                    </div>
            </BookTicketsBtnStyled>
    )
}

export default BookTicketsBtn